import React, { Component } from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home/Home';
import History from './components/History/History';
// import Contact from './component/contact';
// import './App.css';
  
class App extends Component {
  render() {
    return (
       <Router>
           <Routes>
                 <Route exact path='/' element={< Home />}></Route>
                 <Route exact path='/history' element={< History />}></Route>
          </Routes>
       </Router>
   );
  }
}
  
export default App;