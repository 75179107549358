import React from "react";
import NumberList from "../NumberList";
import moment from "moment";
import "./home.css";
function Home() {
  const [numbers, setNumbers] = React.useState([]);
  const [nextRelease, setNextRelease] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const [displayMode, setDisplayMode] = React.useState("FULL");
  const [countdown, setCountdown] = React.useState(null);
  const [banner, setBanner] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRefreshDisabled, setIsRefreshDisabled] = React.useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://api.fastlotwin.net/homeapi");
      const data = await response.json();
      setNumbers(data.numbers);
      setNextRelease(data.next_release);
      setTime(data.time);
      setBanner(data.banner)
      setIsLoading(false);
      setTimeout(() => {
        setIsRefreshDisabled(false);
      }, 5000);
      // Calculate the initial countdown from the current time to the next release

      // Start updating the countdown every second
      const interval = setInterval(() => {
        const now = moment();
        const releaseDate = moment(data.next_release);
        const diff = releaseDate.diff(now);
        const countdownDuration = moment.duration(diff);
        // console.log(diff)
        if (diff < 0) {
          window.location.reload();
        }
        if (
          countdownDuration.seconds() == 10 &&
          countdownDuration.minutes() == 14
        ) {
          window.location.reload();
        }
        if (
          countdownDuration.seconds() == 0 &&
          countdownDuration.minutes() == 0
        ) {
          window.location.reload();
        }
        setCountdown(countdownDuration);
      }, 1000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(interval);
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    // Fetch the numbers data from the API

    fetchData();
  }, []);

  const handleRefreshClick = () => {
    setIsRefreshDisabled(true); // Disable the refresh button
     fetchData();// Perform page refresh
  };

  const renderRows = () => {
    const rows = [];
    if (displayMode === "SINGLE" || displayMode === "TWO") {
      // Add row with values from 0 to 9
      rows.push(
        <tr key="top-row">
          <td></td>
          {[...Array(10)].map((_, index) => (
            <td style={{ background: "rgb(190, 243, 255)" }} key={index}>
              {index.toString().padStart(1, "0")}
            </td>
          ))}
        </tr>
      );

      // Add vertical column with values 00, 10, 20, ... 80, 90
      for (let i = 0; i < 10; i++) {
        rows.push(
          <tr key={`vertical-row-${i}`}>
            <td style={{ background: "rgb(208, 189, 255)" }}>
              {(i * 10).toString().padStart(2, "0")}
            </td>
            {numbers.slice(i * 10, (i + 1) * 10).map((number, index) => (
              <td className="valuetd" key={index}>
                {getDisplayValue(number)}{" "}
                {/* Use helper function to determine the display value */}
              </td>
            ))}
          </tr>
        );
      }
    } else {
      // Regular rows when displayMode is FULL
      for (let i = 0; i < numbers.length; i += 10) {
        const row = numbers.slice(i, i + 10);
        const validNumbers = row.filter((number) => number !== "");
        if (validNumbers.length > 0) {
          rows.push(
            <tr key={i}>
              {validNumbers.map((number, index) => (
                <td className="valuetd" key={index}>
                  {getDisplayValue(number)}{" "}
                  {/* Use helper function to determine the display value */}
                </td>
              ))}
            </tr>
          );
        }
      }
    }
    return rows;
  };

  const getDisplayValue = (number) => {
    if (displayMode === "FULL") {
      return number;
    } else if (displayMode === "TWO") {
      return number.toString().slice(-2); // Display last two digits
    } else if (displayMode === "SINGLE") {
      return number.toString()[2]; // Display the third digit
    }
    return number; // Default case, display all digits
  };
  const handleButtonClick = (mode) => {
    setDisplayMode(mode); // Set the display mode based on the button clicked
  };

  const formatCountdownValue = (value) => {
    // Format the countdown value to have two digits
    return value.toString().padStart(2, "0");
  };

  const formatIndianTime = (time) => {
    return moment.utc(time).utcOffset("+05:30").format("DD-MM-YYYY hh:mm A");
  };

  return (
    <div>
      {isLoading && (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <header>
        <div className="row">
          <div className="col-sm-2">
            <img
              className="logo"
              src="https://api.fastlotwin.net/static/logo.png"
            />
          </div>
          <div className="col-sm-8">
            <div className="timer-container">
              <div className="timer-div">
                <div className="row">
                  <div className="col-sm-6">
                    <h3 style={{ fontSize: 20 }}>
                      Next:{" "}
                      <span id="next_release">
                        {nextRelease &&
                          moment
                            .utc(nextRelease)
                            .utcOffset("+05:30")
                            .format("hh:mm A")}
                      </span>
                    </h3>
                  </div>
                  <div className="col-sm-6">
                    <div id="wrapper">
                      <div className="timebox" id="days">
                        {" "}
                        {countdown && formatCountdownValue(countdown.days())}
                      </div>
                      <div className="timebox" id="hours">
                        {" "}
                        {countdown && formatCountdownValue(countdown.hours())}
                      </div>
                      <div className="timebox" id="minutes">
                        {" "}
                        {countdown && formatCountdownValue(countdown.minutes())}
                      </div>
                      <div className="timebox" id="seconds">
                        {countdown && formatCountdownValue(countdown.seconds())}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2"></div>
          <div className="col-sm-12 buttondiv">
            <span
              id="single"
              className="btn btn-success"
              onClick={() => handleButtonClick("SINGLE")}
            >
              SINGLE
            </span>
            <span
              id="two"
              className="btn btn-success"
              onClick={() => handleButtonClick("TWO")}
            >
              TWO
            </span>
            <span
              id="full"
              className="btn btn-success"
              onClick={() => handleButtonClick("FULL")}
            >
              FULL
            </span>
            <a href="/history" className="history btn btn-success">
              HISTORY
            </a>
            <button
              title="Refresh Page"
              id="refresh"
              className="btn btn-success"
              onClick={handleRefreshClick}
              disabled={isRefreshDisabled}
            >
              <img
                id="refresh-image"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTMuNSAyYy01LjYyMSAwLTEwLjIxMSA0LjQ0My0xMC40NzUgMTBoLTMuMDI1bDUgNi42MjUgNS02LjYyNWgtMi45NzVjLjI1Ny0zLjM1MSAzLjA2LTYgNi40NzUtNiAzLjU4NCAwIDYuNSAyLjkxNiA2LjUgNi41cy0yLjkxNiA2LjUtNi41IDYuNWMtMS44NjMgMC0zLjU0Mi0uNzkzLTQuNzI4LTIuMDUzbC0yLjQyNyAzLjIxNmMxLjg3NyAxLjc1NCA0LjM4OSAyLjgzNyA3LjE1NSAyLjgzNyA1Ljc5IDAgMTAuNS00LjcxIDEwLjUtMTAuNXMtNC43MS0xMC41LTEwLjUtMTAuNXoiLz48L3N2Zz4="
              />
            </button>
          </div>
        </div>
      </header>
      <marquee><h3>{banner && banner}</h3></marquee>
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /><br /> <br />
      {numbers ? (
        <table id="hometable" className="table table-striped">
          <tr>
            <td className="date-col" colspan="11">
              {time && formatIndianTime(time)}
            </td>
          </tr>
          {renderRows()}
        </table>
      ) : (
        <table id="hometable" className="table table-striped">
          <tr>
            <td className="date-col" colspan="11">
              {time && moment(time).format("DD-MM-YYYY hh:mm A")}{" "}
            </td>
          </tr>
          <h4>Results will be published soon. Please wait …</h4>
        </table>
      )}
      <br/><br/><br/><br/>
      <h4>
        Disclaimer: Viewing this website is at your own risk. All the
        information here is based on numeric astrology and is not related to any
        type of gambling. We warn you that gambling in your country may be
        banned or illegal. We are not responsible for any issue or scam. We
        respect all country rules/laws and regulations. If you do not agree with
        our site disclaimer, please quit our site right now. All these numerical
        astrology is only for Sri Lanka Sweepstakes.
      </h4>
    </div>
  );
}

export default Home;
